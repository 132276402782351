import { Injectable } from '@angular/core';
import { ApiHttpService, ApiService } from '@capturum/api';
import { Observable } from 'rxjs';
import { HistoricalUpdate } from '@core/models/historical-update.model';
import { ModelLog } from '@core/models/model-log.model';

@Injectable({
  providedIn: 'root',
})
export class HistoryLogsService extends ApiService<HistoricalUpdate> {
  constructor(public apiHttp: ApiHttpService) {
    super(apiHttp);
  }

  public getHistoryLogs(auctionOrderId: string): Observable<{ [key: string]: ModelLog[] }> {
    const url = `/lot/${auctionOrderId}/history`;

    return this.apiHttp.get(url);
  }
}
