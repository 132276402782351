import { Actions, ofActionSuccessful, Store } from '@ngxs/store';
import { BuildersListKey } from '@core/enums/builders-key.enum';
import { NotificationService } from '@shared/modules/notification/notification.service';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FetchActionsExecution, UpdateChangedItem } from '@store/general/general.actions';
import { BuilderActionType } from '@core/enums/builder-action-type.enum';
import { takeUntil } from 'rxjs/operators';
import { CapturumListRendererComponent } from '@capturum/builders/list-renderer';
import { DestroyBase } from '@core/base/destroy.class';
import { LotService } from '@core/api/lot.service';
import { DownloadLogisticLabelPopupComponent } from '../download-logistic-label-popup/download-logistic-label-popup.component';
import { CapturumBuilderActionService } from '@capturum/builders/core';

@Component({
  selector: 'app-combine-lots',
  templateUrl: './combine-lots.component.html',
  styleUrls: ['./combine-lots.component.scss'],
})
export class CombineLotsComponent extends DestroyBase implements AfterViewInit, OnInit {
  public buildersKey: BuildersListKey = BuildersListKey.listCombineLots;
  public id: string;
  @ViewChild(CapturumListRendererComponent) public listRenderer: CapturumListRendererComponent;

  constructor(
    private dialogService: DialogService,
    private dialogRef: DynamicDialogRef,
    private notificationService: NotificationService,
    private store: Store,
    private translateService: TranslateService,
    private actions: Actions,
    private cdr: ChangeDetectorRef,
    private lotService: LotService,
    private actionService: CapturumBuilderActionService,
    private config: DynamicDialogConfig,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.id = this.config?.data?.item?.map((lot) => {
      return lot.id;
    });
  }

  public ngAfterViewInit(): void {
    this.actions.pipe(ofActionSuccessful(UpdateChangedItem), takeUntil(this.destroy$)).subscribe((response) => {
      this.listRenderer.data = this.listRenderer.data.map((item) => {
        if (item.id === response.item.id) {
          return {
            ...item,
            ...response.item,
          };
        }

        return item;
      });

      this.cdr.detectChanges();
    });
  }

  public onClose(): void {
    this.dialogRef.close();
  }

  public onSave(): void {
    this.lotService.combineLots({ lots: this.listRenderer.data }).subscribe((result) => {
      this.store.dispatch(new FetchActionsExecution(BuilderActionType.closePopup));

      this.notificationService.success(
        this.translateService.instant('toast.success.title'),
        this.translateService.instant('dxp.fulfillment.lots.combine-lots.success.message'),
      );

      if (result?.message) {
        this.notificationService.warning(this.translateService.instant('dxp.toast.warning.title'), result.message);
      }

      this.onClose();
      this.manageDownloadLabelPopUp();
      this.actionService.broadcastActionExecuted(
        {
          options: { refresh: true },
          key: null,
          type: null,
        },
        null,
        null,
      );
    });
  }

  public manageDownloadLabelPopUp(): void {
    this.dialogService.open(DownloadLogisticLabelPopupComponent, {
      header: this.translateService.instant('dxp.lot.list.action.download-logistic-label'),
      styleClass: 'gray-body responsive-dialog padding-0',
      data: {
        lotId: this.id,
        key: this.buildersKey,
      },
    });
  }
}
