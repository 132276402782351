<div class="popup-content">
  <label>{{ 'dxp.fulfillment.lots.combine-lots.label' | translate }}</label>

  <cpb-list-renderer class="white" [context]="{ lot_ids: id }" [key]="buildersKey"> </cpb-list-renderer>
</div>

<div class="popup-actions">
  <cap-button styleClass="secondary mt-3" [label]="'button.cancel' | translate" (onClick)="onClose()"> </cap-button>

  <cap-button
    appPreventDoubleClick
    styleClass="primary ml-2 mt-3"
    [label]="'dxp.fulfillment.lots.button.combine' | translate"
    (clickEvent)="onSave()">
  </cap-button>
</div>
