import { Component, ViewEncapsulation } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { LotService } from '@core/api/lot.service';
import { saveAs } from 'file-saver';
import { finalize, switchMap } from 'rxjs/operators';
import { DownloadLot } from '@core/enums/lot.model';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '@shared/modules/notification/notification.service';
import { BuildersListKey } from '@core/enums/builders-key.enum';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-download-logistic-label-popup',
  templateUrl: './download-logistic-label-popup.component.html',
  styleUrls: ['./download-logistic-label-popup.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DownloadLogisticLabelPopupComponent {
  public lotsId: string | string[];
  public isLoading = false;
  public lots = 0;
  public listKey: BuildersListKey;

  constructor(
    private config: DynamicDialogConfig,
    private lotService: LotService,
    private dialogRef: DynamicDialogRef,
    private translateService: TranslateService,
    private notificationService: NotificationService,
    public http: HttpClient,
  ) {
    // This verification is needed because data could come from bulk action or just through 'confirm-lots' or 'combine-lots'
    this.lotsId = this.config?.data?.item?.id ? this.config.data.item.id : this.config?.data?.lotId;
    this.listKey = this.config?.data?.key;
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  public execute(): void {
    this.isLoading = true;

    if (Array.isArray(this.lotsId)) {
      if (this.listKey === BuildersListKey.listConfirmLots) {
        this.lotsId.forEach((lotId) => {
          this.lotService
            .downloadAuctionLabel(lotId)
            .pipe(
              finalize(() => {
                if (this.lots === this.lotsId.length) {
                  this.isLoading = false;

                  this.closeDialog();
                }
              }),
            )
            .subscribe((response: DownloadLot) => {
              this.lots = this.lots + 1;

              this.handleDownloadedLots(response);
            });
        });
      } else if (this.listKey === BuildersListKey.listCombineLots) {
        this.lotService
          .downloadAuctionLabel(this.lotsId[0])
          .pipe(
            finalize(() => {
              this.isLoading = false;
            }),
          )
          .subscribe((response: DownloadLot) => {
            this.handleDownloadedLots(response);
          });
      }
    } else {
      this.lotService
        .downloadAuctionLabel(this.lotsId.toString())
        .pipe(
          switchMap((updatedDocument: DownloadLot) => {
            return this.downloadFile(updatedDocument?.url);
          }),
          finalize(() => {
            this.isLoading = false;

            this.closeDialog();
          }),
        )
        .subscribe((response) => {
          return this.handleSingleLotDownload(response);
        });
    }
  }

  private handleSingleLotDownload(response: Blob): void {
    if (response) {
      saveAs(response, `auction-label-${new Date().getTime()}.pdf`);

      this.notificationService.success(
        this.translateService.instant('toast.success.title'),
        this.translateService.instant('dxp.lot.download.lot.success.message'),
      );
    }
  }

  private handleDownloadedLots(lot: DownloadLot): void {
    this.downloadFile(lot?.url).subscribe((updatedResponse) => {
      if (updatedResponse) {
        saveAs(updatedResponse, `auction-label-${new Date().getTime()}.pdf`);

        this.notificationService.success(
          this.translateService.instant('toast.success.title'),
          this.translateService.instant('dxp.lot.download.multiple-lots.success.message', {
            lots: this.lots,
            totalLots: this.lotsId.length,
          }),
        );
      }

      this.closeDialog();
    });
  }

  private downloadFile(url: string): Observable<Blob> {
    if (url) {
      return this.http.get(url, { responseType: 'blob' });
    } else {
      return of(null);
    }
  }
}
