import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FulfillmentService } from '@core/api/fulfillment.service';
import { NotificationService } from '@shared/modules/notification/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { ListRendererService } from '@capturum/builders/list-renderer';

@Component({
  selector: 'app-split-shipments-popup',
  templateUrl: './split-shipments-popup.component.html',
  styleUrls: ['./split-shipments-popup.component.scss'],
})
export class SplitShipmentsPopupComponent implements OnInit {
  public warnings: { message: string }[] = [];
  public showWarning = false;
  public errors: string[];
  private shipmentId: string;
  private batchesIds: string[];

  constructor(
    private dialogRef: DynamicDialogRef,
    private dialogConfig: DynamicDialogConfig,
    private fulfillmentService: FulfillmentService,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private listRendererService: ListRendererService,
  ) {}

  public ngOnInit(): void {
    this.shipmentId = this.dialogConfig.data.item[0]?.shipment?.id;
    this.batchesIds = this.dialogConfig.data.item.map((batch) => {
      return batch.id;
    });

    this.splitShipments({ batch_ids: this.batchesIds });
  }

  public onClose(): void {
    this.dialogRef.close();
  }

  public submit(force: boolean): void {
    this.splitShipments({
      batch_ids: this.batchesIds,
      _force: force,
    });
  }

  private splitShipments(payload: { batch_ids: string[]; _force?: boolean }): void {
    this.warnings = [];
    this.errors = [];

    this.fulfillmentService.splitShipments(this.shipmentId, payload).subscribe(
      () => {
        this.notificationService.success(
          this.translateService.instant('toast.success.title'),
          this.translateService.instant('dxp.fulfillment.batches.split_shipments.success'),
        );

        this.onClose();
        this.listRendererService.refreshTable();
      },
      ({ error }) => {
        if (error?.warnings) {
          this.showWarning = true;
          this.warnings = [...this.warnings, { message: error.warnings }];
        }

        if (error?.errors) {
          this.errors = error.errors;
        }
      },
    );
  }
}
