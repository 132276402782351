@if (form) {
  <form [formGroup]="form">
    <div class="popup-content">
      <div class="row right-divider">
        <div class="col-6 detail-column">
          <div class="form-group w-100">
            <cap-input
              data-test="quantity"
              filter="pint"
              formControlName="quantity"
              type="number"
              [label]="'dxp.fulfillment.lots.quantity.label' | translate"
              [placeholder]="'dxp.fulfillment.lots.quantity.label' | translate">
            </cap-input>
          </div>
        </div>
        @if (lotCharacteristics$ | async; as characteristics) {
          <div class="col-6 detail-column">
            <app-boxed-stock-characteristics
              [adjustingProcessName]="adjustingProcess"
              [data]="characteristics"
              [editable]="true">
            </app-boxed-stock-characteristics>
          </div>
        }
      </div>
      @if (showWarning && warnings.length) {
        <div class="d-flex justify-content-center mt-3">
          @for (warning of warnings; track warning) {
            <div class="d-flex justify-content-center warning">
              <i class="mr-2 fas fa-exclamation-triangle"></i>
              @if (warning?.message?.length) {
                <h3>{{ warning.message }}</h3>
              }
            </div>
          }
        </div>
      }
    </div>
    <div class="popup-actions">
      <cap-button styleClass="secondary mt-3" [label]="'dxp.button.cancel-changes' | translate" (onClick)="onClose()">
      </cap-button>
      <cap-button
        appPreventDoubleClick
        [label]="'dxp.button.save-changes' | translate"
        [styleClass]="
          showWarning && warnings[0]?.message?.length !== 0 && errors?.length === 0
            ? 'danger ml-2 mt-3'
            : 'primary ml-2 mt-3'
        "
        (clickEvent)="submit(showWarning)">
      </cap-button>
    </div>
  </form>
}
