<div class="popup-content">
  <cpb-form-renderer [showHeader]="false" [formKey]="formKey" [context]="context"></cpb-form-renderer>
</div>

<div class="popup-actions">
  <cap-button styleClass="secondary mt-3" [label]="'button.cancel' | translate" (onClick)="cancel()"> </cap-button>

  <cap-button
    appPreventDoubleClick
    styleClass="primary ml-2 mt-3"
    [label]="'dxp.button.confirm' | translate"
    (clickEvent)="submit()">
  </cap-button>
</div>
