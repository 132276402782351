<form [formGroup]="form">
  <div class="popup-content">
    <div class="top-part">
      <cap-segmented-buttons
        class="full-width"
        activeStyleClass="active"
        styleClass="bg-white"
        [activeIndex]="selectedTypeIndex"
        [btnTitles]="[
          'dxp.fulfillment.batches.lot.tab.auction' | translate,
          'dxp.fulfillment.batches.lot.tab.client' | translate,
        ]"
        (btnClicked)="orderTypeChanged($event)">
      </cap-segmented-buttons>

      <!-- Auction order -->
      @if (selectedTypeIndex === LotType.auction) {
        <div class="form-group mt-3">
          <label class="semi-bold label">
            {{ 'dxp.fulfillment.batches.lot.sales_channel_id.label' | translate }}
          </label>
          <cap-dropdown
            appendTo="body"
            formControlName="sales_channel_id"
            [attr.data-test]="'sales_channel_id'"
            [filter]="true"
            [options]="salesChannels$ | async"
            [placeholder]="'button.select' | translate">
          </cap-dropdown>
        </div>
        <div class="form-group mt-3">
          <label class="semi-bold label">
            {{ 'dxp.fulfillment.batches.lot.order_date.label' | translate }}
          </label>
          <cap-calendar
            appendTo="body"
            dateFormat="dd-mm-yy"
            formControlName="order_date"
            [attr.data-test]="'order_date'"
            [hideOnDateTimeSelect]="true"
            [placeholder]="'button.select' | translate"
            [selectionMode]="'single'"
            [showWeek]="true">
          </cap-calendar>
        </div>
        <div class="form-group mt-3">
          <label class="semi-bold label">
            {{ 'dxp.fulfillment.batches.lot.quantity.label' | translate }}
          </label>
          <cap-input data-test="quantity" filter="pint" formControlName="quantity" type="number"> </cap-input>
        </div>
      } @else {
        <div class="form-group mt-3">
          <label class="semi-bold label">
            {{ 'dxp.fulfillment.batches.lot.client_organisation_id.label' | translate }}
          </label>
          <cap-dropdown
            appendTo="body"
            formControlName="client_organisation_id"
            [attr.data-test]="'client_organisation_id'"
            [filter]="true"
            [options]="clientList$ | async"
            [placeholder]="'button.select' | translate">
          </cap-dropdown>
        </div>
        <app-sales-order-line-list
          [batchId]="batchId"
          [clientId]="form?.value?.client_organisation_id"
          (selectOrderLine)="getSelectedOrderLine($event)">
        </app-sales-order-line-list>
      }

      <!-- Client order -->
    </div>
  </div>
</form>

<div class="popup-actions">
  <cap-button styleClass="secondary mt-3" [label]="'button.cancel' | translate" (onClick)="onClose()"> </cap-button>

  <cap-button
    appPreventDoubleClick
    styleClass="primary ml-2 mt-3"
    [label]="'dxp.fulfillment.batches.lot.button.create-lot' | translate"
    (clickEvent)="onSave()">
  </cap-button>
</div>
