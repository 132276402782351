<div class="popup-content">
  <cap-button
    appPreventDoubleClick
    styleClass="primary download-button ml-2 mt-3"
    [disabled]="isLoading"
    [icon]="isLoading ? 'fas fa-spinner fa-pulse' : 'fas fa-download'"
    [label]="'dxp.packing-order.tab.download' | translate"
    (clickEvent)="execute()">
  </cap-button>
</div>
