import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { HistoryLogsService } from '../../services/history-logs.service';
import { ModelLog } from '@core/models/model-log.model';

@Component({
  selector: 'app-history-logs',
  templateUrl: './history-logs.component.html',
})
export class HistoryLogsComponent implements OnInit {
  public changes: { [key: string]: ModelLog[] };

  constructor(
    private config: DynamicDialogConfig,
    private historyLogService: HistoryLogsService,
  ) {}

  public ngOnInit(): void {
    this.historyLogService.getHistoryLogs(this.config.data?.item?.id).subscribe((changes) => {
      this.changes = changes;
    });
  }
}
