import { Component, OnInit, Optional } from '@angular/core';
import { BuildersFormKey } from '@core/enums/builders-key.enum';
import { FormSaverService } from '@capturum/builders/form-renderer';
import { FetchActionsExecution } from '@store/general/general.actions';
import { BuilderActionType } from '@core/enums/builder-action-type.enum';
import { Store } from '@ngxs/store';
import { NotificationService } from '@shared/modules/notification/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-match-to-shipment',
  templateUrl: './match-to-shipment.component.html',
  styleUrls: ['./match-to-shipment.component.scss'],
})
export class MatchToShipmentComponent implements OnInit {
  public formKey = BuildersFormKey.matchToShipment;
  public context: { unmatched_supply_line_id: string };

  constructor(
    private formSaver: FormSaverService,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private store: Store,
    private dialogRef: DynamicDialogRef,
    @Optional() private config: DynamicDialogConfig,
  ) {}

  public ngOnInit(): void {
    if (this.config?.data?.item?.id) {
      this.context = { unmatched_supply_line_id: this.config.data.item.id };
    }
  }

  public cancel(): void {
    this.dialogRef.close();
  }

  public submit(): void {
    this.formSaver.submit(this.formKey).subscribe(() => {
      this.store.dispatch(new FetchActionsExecution(BuilderActionType.closePopup));
      this.notificationService.success(
        this.translateService.instant('toast.success.title'),
        this.translateService.instant('dxp.fulfillment.unmatched-lots.match-to-shipment.success'),
      );

      this.cancel();
    });
  }
}
