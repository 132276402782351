<div class="page">
  <cap-info-table
    class="clickable"
    stateKey="order-line-list"
    [columns]="tableColumns"
    [data]="tableData"
    [loading]="!tableData"
    [pagination]="false">
    <ng-template capTemplate="selection" let-item="item">
      <cap-radio [value]="item.tr_style_class ? null : 'true'" (click)="onRadioChange(item)"></cap-radio>
    </ng-template>

    <ng-template capTemplate="stems" let-item="item">
      {{ item?.stems | number | default }}
    </ng-template>

    <ng-template capTemplate="order_date" let-item="item">
      {{ item?.order_date | defaultDateFormatting: 'dd-MM-yyyy' | default }}
    </ng-template>
  </cap-info-table>
</div>
