<div class="popup-content">
  <cpb-form-renderer [formKey]="buildersKey" [showHeader]="false"> </cpb-form-renderer>
</div>

<div class="popup-actions">
  <cap-button styleClass="secondary mt-3" [label]="'dxp.button.cancel-changes' | translate" (onClick)="onClose()">
  </cap-button>

  <cap-button
    appPreventDoubleClick
    styleClass="primary ml-2 mt-3"
    [label]="'dxp.fulfillment.button.move-to-production-line' | translate"
    (clickEvent)="onSave()">
  </cap-button>
</div>
