import { Component, OnInit, ViewChild } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { BaseFormComponent } from '@core/base/base-form.class';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { GradingsService } from '@core/api/gradings.service';
import { GradingFloricodeFeature } from '@core/models/grading.model';
import { BoxedStockCharacteristicsComponent } from '../../../boxed-stock/components/boxed-stock-characteristics/boxed-stock-characteristics.component';
import { BoxedCharacteristicsHelper } from '../../../boxed-stock/helpers/boxed-characteristics.helper';
import { LotService } from '@core/api/lot.service';
import { FulfillmentLot } from '@core/enums/lot.model';
import { FetchActionsExecution } from '@store/general/general.actions';
import { BuilderActionType } from '@core/enums/builder-action-type.enum';
import { CharacteristicsService } from '@core/api/characteristics.service';
import { Entity } from '@core/enums/entity.enum';
import { ListOptions } from '@capturum/api';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { CapturumBuilderActionService } from '@capturum/builders/core';

@Component({
  selector: 'app-edit-lot-characteristics-popup',
  templateUrl: './edit-lot-characteristics-popup.component.html',
  styleUrls: ['./edit-lot-characteristics-popup.component.scss'],
})
export class EditLotCharacteristicsPopupComponent extends BaseFormComponent<FulfillmentLot> implements OnInit {
  public lotId: string;
  public quantity: number;
  public adjustingProcess = Entity.fulfillment;
  public lotCharacteristics$: Observable<GradingFloricodeFeature[]>;
  public warnings: { message: string }[] = [];
  public showWarning = false;
  public errors: string[];
  @ViewChild(BoxedStockCharacteristicsComponent) public characteristics: BoxedStockCharacteristicsComponent;

  protected apiOptions: ListOptions = {
    include: [
      'modelFloricodeFeatures.productFloricodeFeature.floricodeFeatureType',
      'modelFloricodeFeatures.floricodeFeatureValue',
      'grading.gradingFloricodeFeature.floricodeFeatureValue',
      'grading.gradingFloricodeFeature.gradingFloricodeFeatureValues.floricodeFeatureValue',
      'grading.gradingFloricodeFeature.productFloricodeFeature.floricodeFeatureType',
    ],
  };

  constructor(
    protected route: ActivatedRoute,
    private dialogRef: DynamicDialogRef,
    private dialogConfig: DynamicDialogConfig,
    private formBuilder: UntypedFormBuilder,
    private gradingService: GradingsService,
    private lotService: LotService,
    private characteristicsService: CharacteristicsService,
    private actionService: CapturumBuilderActionService,
  ) {
    super(route);
  }

  public ngOnInit(): void {
    this.lotId = this.dialogConfig.data?.item?.id;
    this.quantity = this.dialogConfig.data?.item?.quantity;

    this.createForm();
    this.getLotCharacteristics();
  }

  public getLotCharacteristics(): void {
    this.lotCharacteristics$ = this.lotService.get(this.lotId, this.apiOptions).pipe(
      map((lots) => {
        return this.characteristicsService.mapCharacteristics(lots);
      }),
    );
  }

  public onClose(): void {
    this.dialogRef.close();
  }

  public submit(force: boolean): void {
    this.warnings = [];
    this.errors = [];
    let data = this.form.value;

    data = {
      ...data,
      gradingFloricodeFeature: BoxedCharacteristicsHelper.transformToBackend(this.characteristics?.data),
      _force: force,
    };

    this.lotService.editCharacteristic(data, this.lotId).subscribe(
      () => {
        this.store.dispatch(new FetchActionsExecution(BuilderActionType.closePopup));

        this.notificationService.success(
          this.translateService.instant('toast.success.title'),
          this.translateService.instant('dxp.fulfillment.lots.add-lot-characteristics.success'),
        );

        this.onClose();
        this.actionService.broadcastActionExecuted(
          {
            options: { refresh: true },
            key: null,
            type: null,
          },
          null,
          null,
        );
      },
      ({ error }) => {
        if (error?.warnings) {
          this.showWarning = true;
          this.warnings = [...this.warnings, { message: error.warnings }];
        }

        if (error?.errors) {
          this.errors = error.errors;
        }
      },
    );
  }

  protected createForm(): void {
    this.form = this.formBuilder.group({
      quantity: [this.quantity ?? null, Validators.required],
    });
  }
}
