import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { BaseListReborn } from '@core/base/base-list-reborn.class';
import { InfoTableColumn, InfoTableColumnType } from '@capturum/ui/info-table';
import { ListOptions } from '@capturum/api';
import { OrderLine } from '@core/models/order-line.model';
import { OrderLineService } from '@core/api/order-line.service';

@Component({
  selector: 'app-sales-order-line-list',
  templateUrl: './sales-order-line-list.component.html',
})
export class SalesOrderLineListComponent extends BaseListReborn<OrderLine> implements OnInit, OnChanges {
  public selectedOrderLine: OrderLine;

  @Input() public batchId: string;
  @Input() public clientId: string;
  @Output() public selectOrderLine: EventEmitter<OrderLine> = new EventEmitter<OrderLine>();

  constructor(protected readonly apiService: OrderLineService) {
    super();
  }

  public ngOnInit(): void {
    this.tableColumns = this.getColumns();
    this.tableData = [];
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes?.clientId?.currentValue && this.batchId) {
      this.getTableData();
    }
  }

  public getTableData(apiOptions?: ListOptions): void {
    const payload = {
      client_id: this.clientId,
      batch_id: this.batchId,
    };

    this.tableData = [];

    this.apiService.getClientOrderLines(payload).subscribe((response) => {
      this.tableData = response;
    });
  }

  public onRadioChange(selectedItem: OrderLine): void {
    this.selectedOrderLine = selectedItem;

    this.infoTable.data = this.infoTable?.data?.map((item) => {
      return {
        ...item,
        tr_style_class:
          !this.selectedOrderLine?.tr_style_class && this.selectedOrderLine?.id === item?.id
            ? 'selected-primary'
            : null,
      };
    });
    this.selectOrderLine.emit(selectedItem);
  }

  private getColumns(): InfoTableColumn[] {
    return [
      {
        field: 'selection',
        titleClass: 'flight-custom-width',
        type: InfoTableColumnType.Template,
        disableSort: true,
      },
      {
        field: 'product',
        title: this.translateService.stream('dxp.fulfillment.batches.lot.order-line.product.label'),
        cellClass: 'display-full-text',
      },
      {
        field: 'package_type',
        title: this.translateService.stream('dxp.fulfillment.batches.lot.order-line.package_type.label'),
      },
      {
        field: 'stems',
        title: this.translateService.stream('dxp.fulfillment.batches.lot.order-line.stems.label'),
        type: InfoTableColumnType.Template,
      },
      {
        field: 'order_date',
        title: this.translateService.stream('dxp.fulfillment.batches.lot.order-line.order_date.label'),
        type: InfoTableColumnType.Template,
      },
    ];
  }
}
