<div class="popup-content">
  @if (warnings.length) {
    <div class="row">
      @if (showWarning) {
        <div class="d-flex justify-content-center mt-3">
          @for (warning of warnings; track warning) {
            <div class="d-flex justify-content-center warning">
              <i class="mr-2 fas fa-exclamation-triangle"></i>
              @if (warning?.message?.length) {
                <h3>{{ warning.message }}</h3>
              }
            </div>
          }
        </div>
      }
    </div>
  } @else {
    <cap-skeleton height="35px"></cap-skeleton>
  }
</div>

<div class="popup-actions">
  <cap-button styleClass="secondary mt-3" [label]="'dxp.button.cancel-changes' | translate" (onClick)="onClose()">
  </cap-button>

  <cap-button
    appPreventDoubleClick
    [label]="'dxp.button.save-changes' | translate"
    [styleClass]="
      showWarning && warnings[0]?.message?.length !== 0 && errors?.length === 0
        ? 'danger ms-2 mt-3'
        : 'primary ms-2 mt-3'
    "
    [disabled]="errors?.length > 0"
    [class.pe-none]="errors?.length > 0"
    (clickEvent)="submit(showWarning)">
  </cap-button>
</div>
