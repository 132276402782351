import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Component, OnInit, Optional } from '@angular/core';
import { LotService } from '@core/api/lot.service';
import { LotType } from '@core/enums/lot-type.enum';
import { Observable } from 'rxjs';
import { FilterMatchMode, MapItem } from '@capturum/ui/api';
import { DateHelper } from '@core/utils/date.helper';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { SalesChannelService } from '@core/api/sales-channel.service';
import { BaseFormComponent } from '@core/base/base-form.class';
import { CreateLot, Lot } from '@core/enums/lot.model';
import { ActivatedRoute } from '@angular/router';
import { BuildersListKey } from '@core/enums/builders-key.enum';
import { CapturumBuildersContextService } from '@capturum/builders/core';
import { ListOptions } from '@capturum/api';
import { OrganisationService } from '@core/api/organisation.service';
import { OrderLine } from '@core/models/order-line.model';
import { BaseKey } from '@core/enums/base-key.enum';
import { DxpIndexableBaseDataService } from '@core/indexDb/services/dxp-indexable-base-data.service';

@Component({
  selector: 'app-add-lot-popup',
  templateUrl: './add-lot-popup.component.html',
  styleUrls: ['./add-lot-popup.component.scss'],
})
export class AddLotPopupComponent extends BaseFormComponent<Lot> implements OnInit {
  public selectedTypeIndex: number = LotType.auction;
  public LotType: typeof LotType = LotType;
  public salesChannels$: Observable<MapItem[]>;
  public clientList$: Observable<MapItem[]>;
  public batchId: string;
  public tradeLaneEuropeId: string;

  private packingOrderId: string;
  private orderLineId: string;
  private payload: CreateLot;

  constructor(
    protected apiService: LotService,
    protected route: ActivatedRoute,
    private dialogRef: DynamicDialogRef,
    private formBuilder: UntypedFormBuilder,
    private salesChannelService: SalesChannelService,
    private organisationService: OrganisationService,
    private dxpIndexableBaseDataService: DxpIndexableBaseDataService,
    private readonly contextService: CapturumBuildersContextService,
    @Optional() private config: DynamicDialogConfig,
  ) {
    super(route);
  }

  public ngOnInit(): void {
    const context = this.contextService.getContextByKey(BuildersListKey.listShipmentBatches);

    this.packingOrderId = context?.packing_order_id;
    this.batchId = this.config?.data?.item?.id;

    this.dxpIndexableBaseDataService.getBaseDataValuesByDataKey(BaseKey.tradeLane).subscribe((tradeLanes) => {
      this.tradeLaneEuropeId = tradeLanes.find((tradeLane) => {
        return tradeLane.key === 'trade_lane_europe';
      })?.value;

      this.salesChannels$ = this.salesChannelService.getItemsAsListOptions({
        filters: [
          {
            field: 'trade_lane_base_data_value_id',
            value: this.tradeLaneEuropeId,
          },
          {
            field: 'manual_set_up',
            value: 0,
          },
        ],
      });
    });

    this.createForm();
  }

  public orderTypeChanged(index: number): void {
    if (index === this.selectedTypeIndex) {
      return;
    }

    this.form.reset();

    this.selectedTypeIndex = index;
    const client = this.form.get('client_organisation_id') as UntypedFormControl;

    switch (index) {
      case LotType.client:
        client.setValidators(Validators.required);
        break;
      case LotType.auction:
        client.clearValidators();
        break;
      default:
        break;
    }

    client.updateValueAndValidity();
    this.getClients();
  }

  public getSelectedOrderLine(orderLine: OrderLine): void {
    this.orderLineId = orderLine?.id;
  }

  public onSave(): void {
    this.payload = {
      packing_order_id: this.packingOrderId,
      batch_id: this.batchId,
    };

    if (this.selectedTypeIndex === LotType.auction) {
      this.payload = {
        ...this.payload,
        ...this.form.value,
        order_date: DateHelper.toGeneralFormat(this.form.get('order_date').value),
      };

      delete this.payload.client_organisation_id;
    } else if (this.selectedTypeIndex === LotType.client) {
      this.payload = {
        ...this.payload,
        order_line_id: this.orderLineId,
      };
    }

    this.apiService.create(this.payload).subscribe(() => {
      this.notificationService.success(
        this.translateService.instant('toast.success.title'),
        this.translateService.instant('dxp.fulfillment.batches.lot.success.message'),
      );

      this.onClose();
    });
  }

  public onClose(): void {
    this.dialogRef.close();
  }

  protected createForm(): void {
    this.form = this.formBuilder.group({
      id: [null],
      client_organisation_id: [null, Validators.required],
      order_date: [null],
      sales_channel_id: [null],
      quantity: [null],
    });
  }

  private getClients(): void {
    const options: ListOptions = {
      filters: [
        {
          field: 'type.value',
          value: 'client',
          operator: FilterMatchMode.EQUALS,
        },
      ],
    };

    this.clientList$ = this.organisationService.getItemsAsListOptions(options);
  }
}
